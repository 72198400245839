import type { ThemeConfig } from 'antd'
import { theme as AntTheme } from 'antd'

export const spacing = (factor: number) => 8 * factor

const theme = {
    spacing,
    shape: {
        borderRadius: 4,
    },
    colors: {
        common: {
            black: '#19171C',
            white: '#EFF0EF',
            midGray: '#707070',
        },
        disabled: {
            main: '#7B7A7D',
            light: '#ABA9AD',
            contrastText: '#2F2D33',
        },
        primary: {
            main: '#96BF8B',
            contrastText: '#2F2D32',
        },
        accent: {
            babyPink: '#F3B0C6',
            lightPeriwinkle: '#C7BEFA',
            palePurple: '#948EC9',
            cloudyBlue: '#ABD4E0',
        },
        info: {
            main: '#C9CDB9',
            contrastText: '#2F2D32',
        },
        form: {
            main: '#232126',
            mainLight: '#3D3743',
        },
        header: {
            main: '#0A090B',
            contrastText: '#ABA9AD',
        },
        label: {
            main: '#96BF8B',
            secondary: '#EFF0EF',
            mainLight: '#E2E6DD',
        },
    },
}

export const antTheme: ThemeConfig = {
    hashed: false,
    algorithm: AntTheme.darkAlgorithm,
    token: {
        fontSize: 16,
        fontFamily:
            "Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
        colorPrimaryBg: '#19171C',
        colorPrimary: '#96BF8B',
        colorBgBase: '#19171C',
        colorTextBase: '#EFF0EF',
        colorText: '#EFF0EF',
        linkDecoration: 'underline',
        linkHoverDecoration: 'underline',
        colorLink: 'inherit',
        colorLinkHover: 'inherit',
        colorLinkActive: 'inherit',
        colorInfo: '#C9CDB9',
        wireframe: false,
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 25,
        fontSizeHeading4: 25,
        fontSizeHeading5: 22,
        controlHeight: 40,
        controlHeightLG: 50,
    },
    components: {
        Anchor: {
            colorPrimary: theme.colors.accent.lightPeriwinkle,
            lineType: 'none',
        },
        Button: {
            borderRadius: 999,
            borderRadiusLG: 999,
            paddingContentHorizontal: 16,
            fontSizeLG: 16,
        },
        Cascader: {
            colorBgContainer: '#EFF0EF',
            colorBgContainerDisabled: '#7B7A7D',
            colorText: '#19171C',
            colorTextDisabled: '#2F2D33',
        },
        Checkbox: {
            colorBgContainer: '#EFF0EF',
        },
        DatePicker: {
            colorBgContainer: '#EFF0EF',
            colorBgContainerDisabled: '#7B7A7D',
            controlItemBgActive: '#96BF8B',
            controlItemBgHover: '#96BF8B',
            colorBgElevated: '#EFF0EF',
            colorText: '#19171C',
            colorIcon: '#19171C',
            colorIconHover: '#19171C',
            colorTextHeading: '#19171C',
            colorTextDisabled: '#2F2D33',
            colorTextPlaceholder: '#ABA9AD',
        },
        Input: {
            colorBgContainer: '#EFF0EF',
            colorBgContainerDisabled: '#7B7A7D',
            colorText: '#19171C',
            colorTextDisabled: '#2F2D33',
            lineHeightLG: 1,
        },
        InputNumber: {
            colorBgContainer: '#EFF0EF',
            colorBgContainerDisabled: '#7B7A7D',
            colorText: '#19171C',
            colorTextDisabled: '#2F2D33',
        },
        Layout: {
            bodyBg: '#19171C',
            headerBg: '#0A090B',
        },
        Pagination: {
            borderRadius: 999,
        },
        Segmented: {
            borderRadius: 999,
            itemSelectedBg: '#EFF0EF',
            colorBgLayout: 'transparent',
        },
        Select: {
            colorBgContainer: '#EFF0EF',
            colorBgContainerDisabled: '#7B7A7D',
            colorText: '#19171C',
            colorIcon: '#19171C',
            colorPrimary: '#19171C',
            colorTextQuaternary: '#19171C',
            colorTextDisabled: '#2F2D33',
            colorBgElevated: '#EFF0EF',
            colorTextPlaceholder: '#2F2D32',
            controlItemBgActive: '#ABD4E0',
            controlItemBgHover: '#ABD4E0',
            colorFillSecondary: '#ABD4E0',
        },
        Typography: {
            colorText: '#EFF0EF',
            colorTextDescription: '#ABA9AD',
        },
    },
}

export default theme

import { css } from '@emotion/react'

export default css`
    .ant-space-item {
        max-width: 100%;
    }

    .ant-layout-header .menu-header-project .ant-space-item {
        max-width: 10rem;
    }
`

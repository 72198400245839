import React from 'react'
import { ReactComponent as LogoCarbonClap } from '@assets/logo/carbon-clap.svg'
import { Space, Typography } from 'antd'
import { spacing } from '@/styles/theme'
import { Link, usePage } from '@inertiajs/react'
import useZiggy from '@/hooks/useZiggy'
import MenuMainItem from '@/components/header/MenuMainItem'
import type { CompanyType } from '@/types/Project.type'
import { ProjectSpaceTypeEnum } from '@/types/Project.type'
import useProjectSpace from '@/hooks/useProjectSpace'
import { ReactComponent as IconDashboard } from '@assets/icon/dashboard.svg'
import { ReactComponent as IconProjects } from '@assets/icon/project.svg'
import { ReactComponent as IconManual } from '@assets/icon/manual.svg'
import { useTranslation } from 'react-i18next'

const MenuMain = () => {
    const { getRoute } = useZiggy()
    const { t } = useTranslation('common')
    const { currentSpace, currentCompanyId } = useProjectSpace()
    const companyName = usePage().props.companyName as string
    const companyLogo = usePage().props.companyLogo as string
    const company = usePage().props.company as CompanyType

    const logoSize: number = 120

    return (
        <div css={{ display: 'flex', position: 'relative' }}>
            <Link
                href={getRoute('home')}
                css={(theme) => ({
                    position: 'absolute',
                    display: 'inline-flex',
                    backgroundColor: theme.colors.header.main,
                    padding: spacing(2),
                    borderRadius: '50%',
                })}
            >
                <LogoCarbonClap height={logoSize} />
            </Link>
            <div css={{ display: 'inline-block', width: logoSize + spacing(2 * 2 + 2), height: logoSize }} />

            <Space size={spacing(4)} css={{ marginInlineStart: spacing(4) }} className={'menu-header-project'}>
                {currentCompanyId ? (
                    <MenuMainItem
                        icon={
                            <img
                                css={{ verticalAlign: 'inherit' }}
                                src={company ? company.logo : companyLogo}
                                height={30}
                            />
                        }
                        title={company ? company.name : companyName}
                        route={`${currentSpace}.dashboard`}
                        routeParameters={{ company: currentCompanyId }}
                    />
                ) : (
                    <MenuMainItem
                        icon={<IconDashboard height={30} />}
                        title={t('common:header.menuMain.dashboard')}
                        route={`${currentSpace}.dashboard`}
                        routeParameters={{}}
                    />
                )}

                <MenuMainItem
                    icon={<IconProjects height={30} />}
                    title={
                        currentCompanyId
                            ? t('common:header.menuMain.projects_companies')
                            : t('common:header.menuMain.projects')
                    }
                    route={`${currentSpace}.projects.index`}
                    routeParameters={currentSpace === ProjectSpaceTypeEnum.Company ? { company: currentCompanyId } : {}}
                />

                <a
                    href={t('common:header.menuMain.instructions_url')}
                    target={'_blank'}
                    rel="noreferrer"
                    css={(theme) => ({
                        lineHeight: 1,
                        color: theme.colors.header.contrastText,
                        '&:hover': {
                            color: theme.colors.common.white,
                        },
                        '.anticon': {
                            fontSize: 32,
                        },
                        '.ant-typography': {
                            color: 'inherit',
                            textTransform: 'uppercase',
                            fontSize: 13,
                            fontWeight: 700,
                        },
                    })}
                >
                    <Space direction="vertical" css={{ alignItems: 'center', textAlign: 'center' }}>
                        <IconManual height={30} />
                        <Typography.Text>{t('common:header.menuMain.instructions')}</Typography.Text>
                    </Space>
                </a>
            </Space>
        </div>
    )
}

export default MenuMain

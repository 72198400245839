import React from 'react'
import { css, Global, useTheme } from '@emotion/react'
import get from 'lodash/get'
import font from '@/styles/font'
import anchor from '@/styles/overrides/anchor'
import alert from '@/styles/overrides/alert'
import avatar from '@/styles/overrides/avatar'
import button from '@/styles/overrides/button'
import checkbox from '@/styles/overrides/checkbox'
import dropdownMenu from '@/styles/overrides/dropdownMenu'
import form from '@/styles/overrides/form'
import input from '@/styles/overrides/input'
import link from '@/styles/overrides/link'
import pagination from '@/styles/overrides/pagination'
import segmented from '@/styles/overrides/segmented'
import select from '@/styles/overrides/select'
import space from '@/styles/overrides/space'
import swiper from '@/styles/overrides/swiper'
import table from '@/styles/overrides/table'
import tabs from '@/styles/overrides/tabs'
import tree from '@/styles/overrides/tree'
import typography from './overrides/typography'

type GlobalStylesProps = {
    bodyBg?: string
}

const GlobalStyles = ({ bodyBg = 'colors.common.black' }: GlobalStylesProps) => {
    const theme = useTheme()
    const globalCss = css`
        body {
            background-color: ${get(theme, bodyBg)};
        }
        ${anchor}
        ${alert}
        ${avatar}
        ${button}
        ${checkbox}
        ${dropdownMenu}
        ${font}
        ${form}
        ${input}
        ${link}
        ${pagination}
        ${segmented}
        ${select}
        ${space}
        ${swiper}
        ${table}
        ${tabs}
        ${tree}
        ${typography}
    `

    return <Global styles={globalCss} />
}

export default GlobalStyles

import { css } from '@emotion/react'
import theme from '../theme'

export default css`
    .ant-btn {
        box-shadow: none;
        text-transform: uppercase;
    }

    .ant-btn.ant-btn-lg {
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }

    .ant-btn-primary {
        color: ${theme.colors.primary.contrastText};
        text-decoration: none !important;
    }

    .ant-btn-primary:hover {
        color: ${theme.colors.primary.contrastText} !important;
    }

    .ant-btn-default:disabled,
    .ant-btn-default:disabled:hover {
        color: ${theme.colors.disabled.main} !important;
    }

    .ant-btn.ant-btn-lg > span + .anticon {
        margin-inline-end: -8px;
        margin-inline-start: 20px;
        background-color: ${theme.colors.common.white};
        box-shadow: 0 0 0 10px ${theme.colors.common.white};
        border-radius: 50%;
    }

    span.ant-tooltip-disabled-compatible-wrapper {
        color: rgba(239, 240, 239, 0.25);
    }

    .labelValidation button {
        display: flex;
        align-items: center;
    }

    .ant-btn:hover .spin svg {
        fill: #aabaa6;
    }
`
